import React from 'react';
import imgCheck from '../../assets/ticket/icons/check.svg';
import closeIcon from '../../assets/ticket/icons/close-icon.svg';
import './ticket.scss';

const Ticket = props => {
  const { className, text, check, width, close, onClose = () => {} } = props;
  return (
    <span style={width && { width: `${width}` }} className={className} data-testid='ticket-container-div'>
      {text}
      {check && <img className='ml-2 inline' src={imgCheck} alt='check' data-testid='ticket-check-img' />}
      {close && (
        <img className='ml-2 inline' src={closeIcon} onClick={onClose} alt='close' data-testid='ticket-close-img' />
      )}
    </span>
  );
};

export default Ticket;
