const isComplete = profile => {
  if (
    profile?.genderId &&
    profile?.countryCode !== null &&
    profile?.document_number !== null &&
    profile?.document_type !== null &&
    profile?.document_number?.trim() !== '' &&
    profile?.document_type?.trim() !== '' &&
    profile?.countryCode?.trim() !== '' &&
    profile?.phone !== null &&
    profile?.phone?.trim() !== '' &&
    profile?.address !== null &&
    profile?.address?.countryId &&
    profile?.address?.cityId &&
    profile?.address?.location &&
    profile?.jobRole &&
    profile?.jobRole?.role !== '' &&
    profile?.jobRole?.seniority !== '' &&
    ((profile?.linkedinUrl !== null && profile?.linkedinUrl?.trim() !== '') ||
      (profile?.cvUrl !== null && profile?.cvUrl?.trim() !== ''))
  ) {
    return 2;
  }
  if (
    profile?.birthdate !== null &&
    profile?.address !== null &&
    profile?.address?.countryId &&
    profile?.jobRole !== null &&
    profile?.jobRole?.role !== null &&
    profile?.jobRole?.seniority !== null
  ) {
    return 1;
  }

  return 0;
};

export default isComplete;
