import axios from 'axios';
/* import { createAuthClient } from '../auth0/auth0Client'; */
import { alkymetricsV2BaseURL } from './config';
/* import auth0Cache from '../auth0/customCache'; */

/* const errorsToRetry = [401]; */

const client = axios.create({ baseURL: alkymetricsV2BaseURL });
client.interceptors.request.use(async config => {
  const accessToken = localStorage.getItem('session');
  if (accessToken && !config.newAuthorization) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      ...(config.headers ?? {}),
    };
  }
  if (config.newAuthorization) {
    config.headers = {
      Authorization: config.newAuthorization,
    };
  }
  return config;
});
/* client.interceptors.response.use(
  response => response,
  async error => {
    const auth0Client = await createAuthClient();
    const accessToken = auth0Cache.getAccessToken();
    const originalRequest = error.config;
    if (accessToken && errorsToRetry.includes(error?.response?.status)) {
      if (!originalRequest.retry) {
        originalRequest.retry = true;

        try {
          const newAccessToken = await auth0Client.getTokenSilently();
          originalRequest.newAuthorization = `Bearer ${newAccessToken}`;
          return client(originalRequest);
        } catch (err) {
          return err;
        }
      }
      auth0Client.logout();
    }
    throw error;
  },
); */

export const alkymetricsClient = client;
