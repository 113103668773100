export const optionsAlkymerSignup = {
  CERTIFICATION: 'CERTIFICATION',
  FIND_JOB: 'FIND_JOB',
  IMPROVE_SKILLS: 'IMPROVE_SKILL',
  TEST_INVITATION: 'TEST_INVITATION',
};

export const roles = {
  ADMIN: 'Admin',
  PREACCELERATION: 'Preacceleration',
  APPLICANT: 'Applicant',
  MELI: 'Meli',
  MENTOR: 'Mentor',
  PARTNER: 'Partner',
  ALKYMER: 'Alkymer',
  BETATESTER: 'BetaTester',
  EXCLUSIVEUSER: 'ExclusiveUser',
};

export const answerType = {
  TEXT: 'TEXT',
  RADIO: 'RADIO',
};

export const profileRoleErrors = {
  PROFILE_ROLE_HAS_TO_BE_UPDATED: 'PROFILE_ROLE_HAS_TO_BE_UPDATED',
  PROFILE_ROLE_HAS_TO_BE_SET: 'PROFILE_ROLE_HAS_TO_BE_SET',
};

export const USER_WHITOUT_FORMATION_ROLE = 'S/R';
