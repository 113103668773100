import React from 'react';
import { Controller } from 'react-hook-form';
import { Skeleton } from 'antd';
import DatePicker, { registerLocale } from 'react-datepicker';
import NewStyleInput from '../../NewStyleInput/NewStyleInput';
import NewStyleSelect from '../../NewStyleSelect/NewStyleSelect';
import moment from 'moment';
import es from 'date-fns/locale/es';
import useTranslations from '../../../hooks/useTranslations';

registerLocale('es', es);

const ProfileCardHorizontal = props => {
  const { title, labels, values, column, className, onChange, w33, w66, type, loading, control } = props;
  const { t } = useTranslations();
  return (
    <div className={`flex flex-col ${className}`}>
      {title && <span className={`text-alkemy-black text-base font-bold dark:text-white`}>{title}</span>}
      <div
        className={`flex flex-col ${!column && 'md:flex-col lg:flex-row '} ${
          title && title !== t('PROFILE_ABOUT_ME') ? 'mt-4' : 'mt-0 lg:mt-2'
        }`}>
        {labels.map((value, index) =>
          loading ? (
            <>
              <Skeleton active paragraph={{ rows: 1, width: '80%' }} />
            </>
          ) : (
            <div
              className={`text-dark mt-8 lg:mt-1 ${w33 ? 'w-33' : 'w-full'} ${w33 && w66 && index === 1 && 'w-66'}  ${
                index === 0 || index === 1 ? 'md:mr-2' : null
              }`}
              key={values[index].target}>
              {!values[index].options && values[index].target !== 'birthdate' && (
                <NewStyleInput
                  key={values[index].target}
                  className={values[index].className}
                  title={value}
                  register={values[index].register}
                  validations={values[index].validations}
                  required={values[index].required}
                  type={values[index]?.type ? values[index]?.type : 'text'}
                  value={values[index].value || ''}
                  name={values[index].target}
                  error={{ message: values[index].error }}
                />
              )}
              {values[index].target === 'birthdate' && (
                <div
                  data-testid='input-birthdate'
                  className={`w-full text-sm bg-white dark:bg-transparent dark:text-white ${values[index].className}`}>
                  <div className='mb-1'>
                    <span className='text-sm whitespace-nowrap italic'>{t('PROFILE_DATE_OF_BIRTH')}</span>
                    {values[index].required && <span className='text-red-600 ml-1'>*</span>}
                  </div>
                  <Controller
                    name={values[index].target}
                    {...values[index].register(values[index].target)}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={
                          values[index].value
                            ? new Date(moment(values[index].value).format('YYYY/MM/DD'))
                            : new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())
                        }
                        className='outline-none text-xs border-1 font-semibold border-gray-300 w-full rounded-lg py-1 bg-transparent px-2 truncate'
                        locale='es'
                        dateFormat='dd/MM/yyyy'
                        maxDate={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        onKeyDown={e => {
                          e.preventDefault();
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {values[index].options && (
                <NewStyleSelect
                  className={values[index].className}
                  title={value}
                  required={values[index].required}
                  error={{ message: values[index].error }}
                  name={values[index].target}
                  register={values[index].register}
                  validations={values[index].validations}
                  options={values[index].options}
                />
              )}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default ProfileCardHorizontal;
