import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ActionTypes, useContextState } from '../../../redux/contextState';
import useTranslations from '../../../hooks/useTranslations';
import { getParentRoles, saveProfile } from '../../../api/userService';
import arrowRight from '../../../assets/arrow-small.svg';
import alkemyLogo from '../../../assets/newSteps/logoAlkemy.svg';
import './step3.css';
import InputSearch from '../../../components/Input/InputSearch';
import { seniorityLevelTypes } from '../../../constants/profile';
import { USER_WHITOUT_FORMATION_ROLE } from '../../../constants/enums';

const Step3 = () => {
  const { t } = useTranslations();
  const [roles, setRoles] = useState([]);
  const [rolesFiltered, setRolesFiltered] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [roleSelected, setRoleSelected] = useState();
  const [seniority, setSenioritySelected] = useState('');
  const [showSeniority, setShowSeniority] = useState(false);
  const [roleDisplayName, setRoleDisplayName] = useState('');
  const history = useHistory();
  const { setContextState } = useContextState();

  useEffect(() => {
    getParentRoles().then(response => {
      setContextState({
        type: ActionTypes.SetJobRoles,
        value: response,
      });
      setRolesFiltered(response);
      setRoles(response);
    });
  }, []);

  const saveRoleChanges = async () => {
    const dataToUpdate = { jobRole: { role: roleSelected, seniority: seniority } };
    await saveProfile(dataToUpdate).then(() => {
      history.push('steps?page=4');
      setContextState({
        type: ActionTypes.SetProfile,
        value: dataToUpdate,
      });
    });
  };

  const handleSelectRole = value => {
    setRoleSelected(value);
    if (value !== USER_WHITOUT_FORMATION_ROLE) {
      const roleKey = roles.find(r => r.id === value)?.displayName;
      setRoleDisplayName(t(roleKey));
      setShowSeniority(true);
    } else {
      saveRoleChanges();
    }
  };

  useEffect(() => {
    const results = roles.filter(role => role.displayName.toLowerCase().includes(textSearch.toLowerCase()));
    setRolesFiltered(results);
  }, [textSearch]);

  return (
    <div className='flex flex-col p-8 pt-11' style={{ height: '100%' }}>
      <div className='flex' data-testid='step3-container-logo-div'>
        <img src={alkemyLogo} className='step-logo-alkemy' alt='logo-alkemy' data-testid='step3-logo-img' />
        <img
          src={arrowRight}
          aria-hidden='true'
          onClick={() => history.push('/steps?page=2')}
          className='ml-auto float-right img-arrow-back cursor-pointer'
          data-testid='step3-arrow-back-img'
        />
      </div>
      <div className='mt-5 h-full'>
        <span className='block mb-1 font-bold step1-text dark:text-white' data-testid='step3-your-job-span'>
          {t('STEP_JOB_ROLE_EXPERIENCE')}
        </span>
        {!showSeniority ? (
          <>
            <span className='font-semi-bold step3-text dark:text-white' data-testid='step3-your-job-detail-span'>
              {t('STEP_JOB_ROLE_EXPERIENCE_DETAIL')}
            </span>
            <InputSearch
              textSearch={textSearch}
              setTextSearch={setTextSearch}
              placeHolder='INPUT_SEARCH_PLACEHOLDER_ROL'
            />
            <div
              className='flex flex-col countries-container p-2 form-step-container 2xl:mt-12 max-h-[25rem]'
              data-testid='step3-countries-div'>
              {rolesFiltered.map(role => (
                <button
                  className={`container-country flex flex-row p-3 mt-2 relative cursor-pointer border-0 dark:bg-alkemy-black-bold ${
                    String(role?.id) === String(roleSelected) ? 'selectedOptions' : 'unselectedOptions'
                  }`}
                  key={role.id}
                  value={role.id}
                  onClick={e => handleSelectRole(role?.id)}
                  data-testid={`step3-role-${role.id}`}>
                  <span className='country-text'>{t(`${role?.whitelabelName ?? role?.displayName}`)}</span>
                  <img
                    src={arrowRight}
                    onClick={() => handleSelectRole(role?.id)}
                    className='absolute right-0 mt-1 countries-arrow'
                  />
                </button>
              ))}
            </div>
          </>
        ) : (
          <>
            <span className='font-semi-bold step3-text dark:text-white' data-testid='step3-your-job-detail-level-span'>
              {t('STEP_JOB_ROLE_EXPERIENCE_LEVEL{{role}}').replace('{{role}}', roleDisplayName)}
            </span>
            {seniorityLevelTypes.map(level => (
              <button
                className={`container-country flex flex-row p-3 mt-2 relative cursor-pointer border-0 dark:bg-alkemy-black-bold ${
                  level.name === seniority ? 'selectedOptions' : 'unselectedOptions'
                }`}
                key={level.name}
                placeholder={t('JOB_ROLE_LEVEL_PLACEHOLDER')}
                value={level.name}
                onClick={() => setSenioritySelected(level.name)}
                data-testid={`step3-seniority-${level.name}`}>
                <div className='flex flex-row justify-content-between w-full'>
                  <span className='country-text mr-4'>{t(`${level.name}`)}</span>
                  <img
                    src={arrowRight}
                    onClick={() => setSenioritySelected(level.name)}
                    className='absolute right-0 mt-1 countries-arrow mx-4'
                  />
                </div>
              </button>
            ))}
            <div className='flex flex-row items-center justify-between'>
              <button
                data-testid='back-job-button'
                id='job-back-button'
                onClick={() => {
                  setShowSeniority(false);
                }}
                autoFocus
                className='border-0 text-gray-500 font-bold p-2 px-3 rounded-full mt-5 '>
                {t('GO_BACK')}
              </button>
              <button
                data-testid='conntinue-job-button'
                id='job-continue-button'
                onClick={() => {
                  saveRoleChanges();
                }}
                autoFocus
                disabled={!seniority}
                className={`border-0 text-white font-bold p-2 px-3 rounded-full mt-5 ${
                  !seniority ? 'bg-gray-500' : 'bg-maya-blue'
                }`}>
                {t('CONTINUE')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Step3;
