import IconSearch from '../../assets/IconSearch';
import useTranslations from '../../hooks/useTranslations';

const InputSearch = ({ textSearch, setTextSearch, placeHolder = 'INPUT_SEARCH_PLACEHOLDER_EJ' }) => {
  const { t } = useTranslations();
  return (
    <div
      className={`flex border-2 rounded-lg ${
        textSearch.length ? 'border-alkemy-blue' : 'border-gray-400'
      } py-1 px-2 my-6 bg-shadowGray`}>
      <IconSearch active={textSearch.length} />
      <input
        className='focus:outline-none pl-2 text-xs bg-shadowGray font-medium w-full font-montserrat placeholder:italic placeholder:font-normal'
        onChange={e => setTextSearch(e.target.value)}
        type='text'
        placeholder={t(placeHolder)}
        data-testid='step2-search-input'
      />
    </div>
  );
};

export default InputSearch;
