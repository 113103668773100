import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import { ActionTypes, useContextState } from '../../../redux/contextState';
import useTranslations from '../../../hooks/useTranslations';
import { saveProfile } from '../../../api/userService';
import arrowRight from '../../../assets/arrow-small.svg';
import alkemyLogo from '../../../assets/newSteps/logoAlkemy.svg';
import './step4.css';
import { optionsAlkymerSignup } from '../../../constants/enums';

const Step4 = () => {
  const { t } = useTranslations();
  const [countries, setCountries] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const history = useHistory();
  const { contextState, setContextState } = useContextState();
  const welcomePage = contextState?.whitelabel?.routes?.includes('WELCOME');
  const options = [
    {
      id: 1,
      displayName: optionsAlkymerSignup.TEST_INVITATION,
      url: 'email',
    },
    {
      id: 2,
      displayName: optionsAlkymerSignup.CERTIFICATION,
      url: 'certificate',
    },
    {
      id: 3,
      displayName: optionsAlkymerSignup.IMPROVE_SKILLS,
      url: 'education',
    },
    {
      id: 4,
      displayName: optionsAlkymerSignup.FIND_JOB,
      url: 'job',
    },
  ];

  const toggleOption = option => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const changeOptions = async () => {
    const dataToUpdate = { reasonCreation: selectedOptions };
    await saveProfile(dataToUpdate).then(() => {
      setContextState({
        type: ActionTypes.SetProfile,
        value: dataToUpdate,
      });
      if (welcomePage) {
        history.push({
          pathname: '/welcome',
        });
      } else {
        if (localStorage.getItem('prevUrlLogin')) {
          history.push({
            pathname: '/home',
            hash: '#welcome',
            search: stringify(JSON.parse(localStorage.getItem('prevUrlLogin'))),
          });
        } else {
          history.push({
            pathname: '/home',
            hash: '#welcome',
          });
        }
      }
    });
  };

  return (
    <div className='flex flex-col p-8 pt-11' style={{ height: '100%' }}>
      <div className='flex' data-testid='step4-container-logo-div'>
        <img src={alkemyLogo} className='step-logo-alkemy' alt='logo-alkemy' data-testid='step4-logo-img' />
        <img
          src={arrowRight}
          aria-hidden='true'
          onClick={() => history.push('/steps?page=3')}
          className='ml-auto float-right img-arrow-back cursor-pointer'
          data-testid='step4-arrow-back-img'
        />
      </div>
      <div className='mt-5 h-full'>
        <span className='font-bold step1-text dark:text-white' data-testid='step4-your-reason-span'>
          {t('STEP_WHY_ALKEMY')}
        </span>
        <div
          className='flex flex-col countries-container p-2 form-step-container 2xl:mt-12 max-h-[25rem]'
          data-testid='step4-countries-div'>
          {options.map(option => (
            <button
              className={`container-reason flex flex-row p-3 mt-2 relative cursor-pointer border-0 dark:bg-alkemy-black-bold ${
                selectedOptions.includes(option.displayName) ? 'selectedOptions' : 'unSelectedOptions'
              }`}
              key={option.id}
              onClick={() => toggleOption(option.displayName)}
              data-testid={`step4-reason-${option.id}`}>
              <img
                src={`${process.env.RAZZLE_RUNTIME_ASSETS}/assets/optionsCampus/${option?.url}.svg`}
                className='mr-3 mt-1 icon-flag-img'
                aria-hidden='true'
                onClick={() => toggleOption(option.displayName)}
              />
              <span className='option-text' onClick={() => toggleOption(option.displayName)}>
                {t(option?.displayName)}
              </span>
              <img
                src={arrowRight}
                onClick={() => option?.displayName(option?.displayName)}
                className='absolute right-0 mt-1 countries-arrow'
              />
            </button>
          ))}
        </div>
        <div className='flex flex-col items-center justify-center'>
          <button
            data-testid='register-button'
            id='register-submit-button'
            onClick={() => {
              changeOptions();
            }}
            autoFocus
            disabled={selectedOptions?.length === 0}
            className={`border-0 text-white font-bold p-2 px-3 rounded-full mt-5 ${
              selectedOptions?.length === 0 ? 'bg-gray-500' : 'bg-maya-blue'
            }`}>
            {t('FINISHED')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step4;
