import React, { useContext } from 'react';
import { Window } from '../utils/windowUtils';

export const initialState = {
  loading: true,
  socialMedias: [],
  address: {},
  profile: {},
  email: '',
  phone: {},
  skills: {},
  techSkills: [],
  acceptedAssessmentInvitations: [],
  role: '',
  events: [],
  videos: [],
  videoSelected: {},
  certification: {},
  certificate: '',
  teams: [],
  countries: [],
  formation_status: [],
  formations: [],
  studyAreas: [],
  technologies: [],
  languages: [],
  levels: [],
  advance: {},
  profileNews: {},
  profileNotifications: {
    collection: [],
    pagination: {},
  },
  profileAnnouncements: [],
  profileChallenges: [],
  meetings: [],
  pdf: [],
  integratorsInfo: {},
  ux: {
    sidebar: { collapsed: true },
    language: Window()?.localStorage?.getItem('language') ?? 'es',
  },
  editable: false,
  route: '',
  show: false,
  error: false,
  notificationDropdown: false,
  mentor: {},
  announcements: [],
  queryParams: '',
  prevUrl: '',
  isAuthenticated: false,
  askMultiplatformAccount: false,
  isVerify: false,
  emailVerify: '',
  whitelabel: {},
  currentResult: undefined,
  loginLoading: false,
  roles: [],
  screenings: [],
  tests: [],
  screeningRedirection: {
    technologyId: undefined,
    levelId: undefined,
    screeningId: undefined,
  },
  theme: 'light',
  termsAndConditions: {
    showModal: false,
    termsAndConditionsLoading: false,
    termsAndConditionsData: null,
  },
  genders: [],
  tokenPayload: {},
  exclusiveInvitation: undefined,
  jobRoleUpdateErrorOn: null,
  jobRoles: [],
  jobRole: {
    role: '',
    seniority: '',
  },
};

export const ActionTypes = {
  SetLoading: 'SET_LOADING',
  SetSocialMedias: 'SET_SOCIAL_MEDIAS',
  SetAddress: 'SET_ADDRESS',
  SetProfile: 'SET_PROFILE',
  SetPhone: 'SET_PHONE',
  SetEvents: 'SET_EVENTS',
  SetVideos: 'SET_VIDEOS',
  SetVideoSelected: 'SET_VIDEO_SELECTED',
  SetCertification: 'SET_CERTIFICATION',
  SetImageProfile: 'SET_IMAGE_PROFILE',
  SetCertificate: 'SET_CERTIFICATE',
  SetCountries: 'SET_COUNTRIES',
  SetGenders: 'SET_GENDERS',
  SetFormations: 'SET_FORMATIONS',
  SetFormationStatus: 'SET_FORMATION_STATUS',
  SetFormationProfile: 'SET_FORMATION_PROFILE',
  SetTechnologies: 'SET_TECHNOLOGIES',
  SetDataProfile: 'SET_DATA_PROFILE',
  SetLanguages: 'SET_LANGUAGES',
  SetLevels: 'SET_LEVELS',
  SetAdvance: 'SET_ADVANCE',
  SetProfileNews: 'SET_PROFILE_NEWS',
  SetMentor: 'SET_MENTOR',
  SetProfileTeam: 'SET_PROFILE_TEAM',
  SetProfileNotifications: 'SET_PROFILE_NOTIFICATIONS',
  SetProfileAnnouncements: 'SET_PROFILE_ANNOUNCEMENTS',
  SetProfileChallenges: 'SET_PROFILE_CHALLENGES',
  SetMeetings: 'SET_MEETINGS',
  SetSidebarCollapsed: 'SET_SIDEBAR_COLLAPSED',
  SetPdf: 'SET_PDF',
  SetEditable: 'SET_EDITABLE',
  SetShow: 'SET_SHOW',
  SetRoute: 'SET_ROUTE',
  SetError: 'SET_ERROR',
  SetIntegrators: 'SET_INTEGRATORS',
  SetNotif: 'SET_NOTIF',
  SetNotificationDropdown: 'SET_NOTIFICATION_DROPDOWN',
  SetAnnouncements: 'SET_ANNOUNCEMENTS',
  SetQueryParams: 'SET_QUERY_PARAMS',
  SetPrevUrl: 'SET_PREV_URL',
  SetAuthenticated: 'SET_AUTHENTICATED',
  SetAskMultiplatformAccount: 'SET_ASK_MULTIPLATFORM_ACCOUNT',
  SetVerify: 'SET_VERIFY',
  SetEmailVerify: 'SET_EMAIL_VERIFY',
  SetWhitelabel: 'SET_WHITELABEL',
  SetUxLanguage: 'SET_UX_LANGUAGE',
  SetRoles: 'SET_ROLES',
  SetCurrentResult: 'SET_CURRENT_RESULT',
  SetScreenings: 'SET_SCREENINGS',
  SetTests: 'SET_TESTS',
  SetLoginLoading: 'SET_LOGIN_LOADING',
  SetScreeningRedirection: 'SET_SCREENING_REDIRECTION',
  SetTheme: 'SET_THEME',
  SetStudyAreas: 'SET_STUDY_AREAS',
  SetAcceptedAssessmentInvitations: 'SET_ACCEPTED_ASSESSMENT_INVITATIONS',
  SetTermsAndConditions: 'SET_TERMS_AND_CONDITIONS',
  SetRedirectScreening: 'SET_REDIRECT_SCREENING',
  SetTokenPayload: 'SET_TOKEN_PAYLOAD',
  SetExclusiveInvitation: 'SET_EXCLUSIVE_INVITATION',
  SetJobRoles: 'SET_JOB_ROLES',
  SetJobRoleUpdateReminder: 'SET_JOB_ROLE_UPDATE_REMINDER',
};

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SetNotif: {
      const a = state.profileNotifications.collection.push(action.value);
      return {
        ...state,
        profileNotifications: {
          ...state.profileNotifications,
        },
      };
    }
    case ActionTypes.SetLoading:
      return {
        ...state,
        loading: action.value,
      };
    case ActionTypes.SetSocialMedias:
      return {
        ...state,
        socialMedias: action.value,
        loading: false,
      };
    case ActionTypes.SetAddress:
      return {
        ...state,
        profile: { ...state.profile, address: action.value },
        loading: false,
      };
    case ActionTypes.SetProfile:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.value,
        },
      };
    case ActionTypes.SetPhone:
      return {
        ...state,
        phone: action.value,
        loading: false,
      };
    case ActionTypes.SetEvents:
      return {
        ...state,
        events: action.value,
        loading: false,
      };
    case ActionTypes.SetVideos:
      return {
        ...state,
        videos: action.value,
        loading: false,
      };
    case ActionTypes.SetVideoSelected:
      return {
        ...state,
        videoSelected: action.value,
        loading: false,
      };
    case ActionTypes.SetCertification:
      return {
        ...state,
        certification: action.value,
        loading: false,
      };
    case ActionTypes.SetImageProfile:
      return {
        ...state,
        profile: { ...state.profile, picture: action.value },
        loading: false,
      };
    case ActionTypes.SetCertificate:
      return {
        ...state,
        certificate: action.value,
        loading: false,
      };
    case ActionTypes.SetCountries:
      return {
        ...state,
        countries: action.value,
        loading: false,
      };
    case ActionTypes.SetGenders:
      return {
        ...state,
        genders: action.value,
        loading: false,
      };
    case ActionTypes.SetFormations:
      return {
        ...state,
        formations: action.value,
        loading: false,
      };
    case ActionTypes.SetFormationStatus:
      return {
        ...state,
        formation_status: action.value,
        loading: false,
      };
    case ActionTypes.SetFormationProfile:
      return {
        ...state,
        profile: { ...state.profile, formations: action.value },
        loading: false,
      };
    case ActionTypes.SetTechnologies:
      return {
        ...state,
        technologies: action.value,
        loading: false,
      };
    case ActionTypes.SetLanguages:
      return {
        ...state,
        languages: action.value,
        loading: false,
      };
    case ActionTypes.SetLevels:
      return {
        ...state,
        levels: action.value,
        loading: false,
      };
    case ActionTypes.SetDataProfile:
      return {
        ...state,
        profile: { ...state.profile, [action.object]: action.value },
        loading: false,
      };
    case ActionTypes.SetAdvance:
      return {
        ...state,
        advance: action.value,
      };
    case ActionTypes.SetProfileNews:
      return {
        ...state,
        profileNews: action.value,
      };
    case ActionTypes.SetMentor:
      return {
        ...state,
        mentor: action.value,
      };
    case ActionTypes.SetProfileTeam:
      return {
        ...state,
        teams: action.value,
      };
    case ActionTypes.SetProfileNotifications:
      return {
        ...state,
        profileNotifications: action.value,
      };
    case ActionTypes.SetAnnouncements:
      return {
        ...state,
        announcements: action.value,
      };
    case ActionTypes.SetProfileAnnouncements:
      return {
        ...state,
        profileAnnouncements: action.value,
      };
    case ActionTypes.SetProfileChallenges:
      return {
        ...state,
        profileChallenges: action.value,
      };
    case ActionTypes.SetMeetings:
      return {
        ...state,
        meetings: action.value,
      };
    case ActionTypes.SetSidebarCollapsed:
      return {
        ...state,
        ux: {
          ...state.ux,
          sidebar: { collapsed: action.value },
        },
      };
    case ActionTypes.SetPdf:
      return {
        ...state,
        pdf: action.value,
      };
    case ActionTypes.SetEditable:
      return {
        ...state,
        editable: action.value,
      };
    case ActionTypes.SetShow:
      return {
        ...state,
        show: action.value,
      };
    case ActionTypes.SetRoute:
      return {
        ...state,
        route: action.value,
      };
    case ActionTypes.SetIntegrators:
      return {
        ...state,
        integratorsInfo: action.value,
      };
    case ActionTypes.SetNotificationDropdown:
      return {
        ...state,
        notificationDropdown: action.value,
      };
    case ActionTypes.SetError:
      return {
        ...state,
        error: true,
      };
    case ActionTypes.SetQueryParams:
      return {
        ...state,
        queryParams: action.value,
      };
    case ActionTypes.SetPrevUrl:
      return {
        ...state,
        prevUrl: action.value,
      };
    case ActionTypes.SetAuthenticated:
      return {
        ...state,
        isAuthenticated: action.value,
      };
    case ActionTypes.SetAskMultiplatformAccount:
      return {
        ...state,
        askMultiplatformAccount: action.value,
      };
    case ActionTypes.SetVerify:
      return {
        ...state,
        isVerify: action.value,
      };
    case ActionTypes.SetEmailVerify:
      return {
        ...state,
        emailVerify: action.value,
      };
    case ActionTypes.SetWhitelabel:
      return {
        ...state,
        whitelabel: action.value,
      };
    case ActionTypes.SetCurrentResult:
      return {
        ...state,
        currentResult: action.value,
      };
    case ActionTypes.SetScreenings:
      return {
        ...state,
        screenings: action.value,
      };
    case ActionTypes.SetUxLanguage: {
      return {
        ...state,
        ux: {
          ...state.ux,
          language: action.value,
        },
      };
    }
    case ActionTypes.SetTheme: {
      return {
        ...state,
        theme: action.value,
      };
    }
    case ActionTypes.SetRoles:
      return {
        ...state,
        roles: action.value,
      };
    case ActionTypes.SetTests:
      return {
        ...state,
        tests: action.value,
      };
    case ActionTypes.SetLoginLoading:
      return {
        ...state,
        loginLoading: action.value,
      };
    case ActionTypes.SetStudyAreas:
      return {
        ...state,
        studyAreas: action.value,
      };
    case ActionTypes.SetScreeningRedirection:
      return {
        ...state,
        screeningRedirection: action.value,
      };
    case ActionTypes.SetAcceptedAssessmentInvitations:
      return {
        ...state,
        acceptedAssessmentInvitations: action.value,
      };
    case ActionTypes.SetTermsAndConditions:
      return {
        ...state,
        termsAndConditions: action.value,
      };
    case ActionTypes.SetRedirectScreening:
      return {
        ...state,
        redirectScreening: action.value,
      };
    case ActionTypes.SetTokenPayload:
      return {
        ...state,
        tokenPayload: action.value,
      };
    case ActionTypes.SetExclusiveInvitation:
      return {
        ...state,
        exclusiveInvitation: action.value,
      };
    case ActionTypes.SetJobRoles:
      return {
        ...state,
        jobRoles: action.value,
      };
    case ActionTypes.SetJobRoleUpdateReminder:
      return {
        ...state,
        jobRoleUpdateErrorOn: new Date().getTime(),
      };
    default:
      return state;
  }
};

export const initialContext = {
  contextState: initialState,
  setContextState: () => {},
};

const Cont = React.createContext(initialContext);

export function ContextProvider({ children, initial = initialState }) {
  const [state, dispatch] = React.useReducer(reducer, initial);

  const contextState = state;
  const setContextState = dispatch;

  return <Cont.Provider value={{ contextState, setContextState }}>{children}</Cont.Provider>;
}

export const useContextState = () => useContext(Cont);
