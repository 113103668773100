import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useRef, useState } from 'react';
import { useContextState, ActionTypes } from '../../redux/contextState';
import notificationIconEmpty from '../../assets/header/notifications-empty.svg';
import notificationsPlaceholder from '../../assets/header/notifications-placeholder.svg';
import moment from 'moment';
import { getProfileNotifications } from '../../api/userService';
import { readNotifications } from '../../api/notificationService';
import leftArrow from '../../assets/header/arrow-small-left.svg';
import useClickOutside from '../../hooks/useClickOutside';
import useTranslations from '../../hooks/useTranslations';
import CustomSVG from '../../components/CustomSVG/CustomSVG';
import NotificationsEmpty from '../../assets/header/notifications-empty';
import './NotificationsDropdown.css';

const NotificationsDropdown = ({ options, className }) => {
  const { t } = useTranslations();
  const accessToken = localStorage.getItem('session');
  const ref = useRef(null);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const { contextState, setContextState } = useContextState();
  useClickOutside(ref, () => setNotificationDropdown(false), notificationDropdown);
  const unreadNotifications = options?.collection
    ? options?.collection?.filter(item => !item.isRead).map(item => ({ id: item.id, createdAt: item.createdAt }))
    : [];

  const handleRead = () => {
    if (unreadNotifications.length) {
      readNotifications(contextState?.profile?.id, unreadNotifications).then(res => {
        setTimeout(() => {
          getProfileNotifications()
            .then(response => {
              setContextState({
                type: ActionTypes.SetProfileNotifications,
                value: response,
              });
            })
            .catch(err => {
              Sentry.captureException(err);
              setContextState({
                type: ActionTypes.SetProfileNotifications,
                value: [],
              });
            });
        }, 2000);
      });
    }
  };

  useEffect(() => {
    if (notificationDropdown && unreadNotifications.length) {
      handleRead();
    }
  }, [notificationDropdown]);

  return (
    <div
      onClick={handleRead}
      ref={ref}
      className={`notifications-container self-center ${
        unreadNotifications.length && 'new-notifications'
      } ${className}`}>
      <CustomSVG
        onClick={() => setNotificationDropdown(!notificationDropdown)}
        alt={t('ICON_NOTIFICATIONS')}
        className='hidden lg:block relative cursor-pointer'>
        <NotificationsEmpty color={contextState.theme === 'dark' ? '#ffffff' : 'grey'} />
      </CustomSVG>
      <CustomSVG
        onClick={() => setNotificationDropdown(!notificationDropdown)}
        alt={t('ICON_NOTIFICATIONS')}
        className='lg:hidden block relative cursor-pointer'>
        <NotificationsEmpty color={contextState.theme === 'dark' ? '#ffffff' : 'grey'} />
      </CustomSVG>
      <ul
        className={`notifications-list list-unstyled absolute bg-white dark:bg-dark-bold ${
          notificationDropdown ? 'block show overflow-auto' : 'hidden'
        }`}>
        <li
          onClick={() => setNotificationDropdown(!notificationDropdown)}
          className='text-maya-blue py-4 pl-4 flex items-center lg:hidden font-bold font-roboto font-small cursor-pointer dark:text-white'>
          <img src={leftArrow} className='pr-2' />
          {t('STEPS_BACK_TEXT')}
        </li>
        <li
          onClick={() => setNotificationDropdown(!notificationDropdown)}
          className='py-3 notifications-title font-roboto items-center font-bold px-5 lg:px-4 flex justify-between cursor-pointer dark:text-white'>
          {t('NOTIFICATIONS_NOTIFICATIONS')}{' '}
          <span className='hidden lg:block close-notifications-text font-roboto text-maya-blue uppercase dark:text-white'>
            {t('NOTIFICATIONS_CLOSE')}
          </span>
          <img src={notificationIconEmpty} className='lg:hidden block' />
        </li>
        {options?.collection?.length ? (
          options?.collection
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((option, i) => (
              <a
                href={option?.sender === 'LMS' ? `${option.actionUrl}?token=${accessToken}` : option.actionUrl}
                target='_blank'
                rel='noreferrer'
                className={`lg:py-3 text-decoration-none bg-white dark:bg-dark-bold notifications-item py-4 px-5 lg:px-4 cursor-pointer flex flex-col ${
                  option.isRead ? 'readed-notification' : 'border-top'
                }`}
                key={i}
                data-testid={`notification-container-${option.id}`}>
                <span className='font-small pb-1 text-alkemy-black-bold hover:text-alkemy-black-bold text-pre-line dark:text-white'>
                  {option.description}
                </span>
                <span
                  className='font-tiny text-muted dark:text-white'
                  data-testid={`notification-container-date-${option.id}`}>
                  {moment(option?.createdAt).local().fromNow()}
                </span>
              </a>
            ))
        ) : (
          <div className='flex flex-col items-center py-4'>
            <div className='bg-gray dark:bg-transparent notifications-placeholder-background flex items-center mb-4 justify-center round-img'>
              <img src={notificationsPlaceholder} />
            </div>
            <span className=' font-small dark:text-white'>{t('NOTIFICATIONS_PLACEHOLDER')}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default NotificationsDropdown;
