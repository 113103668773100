import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import routes from './config/routes';
import Spinner from './components/Spinner/Spinner';
import { ActionTypes, useContextState } from './redux/contextState';
import { auth0Client } from './auth0/auth0';
import { IntlProvider } from 'react-intl';
import useTranslations from './hooks/useTranslations';
import { Window } from './helpers/window';
import { ProtectedRoute, PublicRoute } from './guards';
import { ErrorBoundary } from '@sentry/react';
import isSomeRoles from './utils/isSomeRoles';
import { queryToObject } from './utils/processQueryString';
import useRedirect from './hooks/useRedirect';
import { notification } from 'antd';
import { formatTokenPayload } from './utils/formatTokenPayload';
import './index.css';
import './padding.scss';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getWhiteLabel, getExclusiveInvitation, getColorAndLogoCompany } from './api/userService';

function App() {
  const history = useHistory();
  const { language, key, t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const { handleError } = useRedirect();
  const queryClient = new QueryClient();

  useEffect(() => {
    if (Window()) {
      if (contextState?.theme === 'dark') {
        setContextState({
          type: ActionTypes.SetTheme,
          value: 'dark',
        });
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
        setContextState({
          type: ActionTypes.SetTheme,
          value: 'light',
        });
      }
    }
  }, [contextState.theme]);

  useEffect(() => {
    if (localStorage?.getItem('theme') === 'dark') {
      setContextState({
        type: ActionTypes.SetTheme,
        value: 'dark',
      });
    } else {
      setContextState({
        type: ActionTypes.SetTheme,
        value: 'light',
      });
    }
  }, []);

  useEffect(() => {
    if (Window()) {
      const queryParams = queryToObject(history.location.search);

      const tagManagerArgs = {
        gtmId: process.env.RAZZLE_RUNTIME_TAG_MANAGER_ID,
      };
      TagManager.initialize(tagManagerArgs);
      if (queryParams?.screeningId || (queryParams?.technologyId && queryParams?.levelId)) {
        setContextState({
          type: ActionTypes.SetScreeningRedirection,
          value: {
            screeningId: queryParams?.screeningId,
            technologyId: queryParams?.technologyId,
            levelId: queryParams?.levelId,
          },
        });
      }
      if (history.location.pathname !== '/login' && history.location.pathname !== '/') {
        window.localStorage.setItem(
          'prevUrl',
          JSON.stringify(`${history.location.pathname}${history.location.search}`),
        );
      }
      if (history.location.hash && !history.location.hash.includes('#access_token')) {
        window.localStorage.setItem('prevHash', window.location.hash);
      }
      if (!contextState.isVerify && queryParams?.invitation) {
        window.localStorage.setItem('prevRecruitSessionInvitation', queryParams?.invitation);
      }
      if (queryParams?.invitationId || queryParams?.invitation) {
        const fetchExclusive = async () => {
          const shortId = queryParams?.invitation ?? queryParams?.invitationId;
          const response = await getExclusiveInvitation(shortId);
          const exclusive = response?.isExclusive ?? false;
          setContextState({ type: ActionTypes.SetExclusiveInvitation, value: exclusive });
        };
        fetchExclusive();
      }
      if (queryParams?.serverError) {
        notification.open({ message: t(queryParams.serverError), type: 'error' });
      }

      auth0Client().checkSession(
        {
          audience: process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE,
          scope: 'read:current_user',
          getProfileData: true,
        },
        function (err, result) {
          if (err === null && result.accessToken) {
            const formattedToken = formatTokenPayload(
              result.idTokenPayload ?? {},
              process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE,
            );

            setContextState({
              type: ActionTypes.SetRoles,
              value: formattedToken.roles,
            });
            localStorage.setItem('session', result.accessToken);
            localStorage.setItem('roles', JSON.stringify(formattedToken.roles));

            setContextState({
              type: ActionTypes.SetTokenPayload,
              value: formattedToken,
            });
            setContextState({
              type: ActionTypes.SetAskMultiplatformAccount,
              value: formattedToken.ask_create_multiplatform_account ?? false,
            });
            setContextState({
              type: ActionTypes.SetAuthenticated,
              value: true,
            });
            setContextState({
              type: ActionTypes.SetVerify,
              value: formattedToken.email_verified,
            });
            setContextState({
              type: ActionTypes.SetEmailVerify,
              value: formattedToken.email,
            });
          } else {
            setContextState({
              type: ActionTypes.SetAuthenticated,
              value: false,
            });

            const prevUrlLogin = {
              email: queryParams?.email,
              invitationId: queryParams?.invitationId,
              recruitSessionStageId: queryParams?.recruitSessionStageId,
              invitation: queryParams?.invitation,
              challenge: queryParams?.challenge,
            };
            window.localStorage.setItem('prevUrlLogin', JSON.stringify(prevUrlLogin));

            const params = {
              email: queryParams?.email,
              invitationId: queryParams?.invitationId,
              recruitSessionStageId: queryParams?.recruitSessionStageId,
              invitation: queryParams?.invitation,
              name: queryParams?.name,
              lastName: queryParams?.lastName,
            };

            const logoutRoutes = ['/register', '/login'];
            history.push({
              pathname: logoutRoutes.includes(history.location.pathname) ? history.location.pathname : '/register',
              search: queryParams
                ? '?' + decodeURIComponent(new URLSearchParams(JSON.parse(JSON.stringify(params))).toString())
                : '',

              hash:
                (queryToObject(history.location.hash)?.error_description
                  ? `#error_description=${queryToObject(history.location.hash)?.error_description}&`
                  : '') + (localStorage.getItem('prevHash') ? localStorage.getItem('prevHash') : '').replace(/\s/g, ''),
            });
            setContextState({
              type: ActionTypes.SetLoading,
              value: false,
            });
          }
        },
      );
    }
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (contextState?.ux?.language) {
        setContextState({
          type: ActionTypes.SetLoading,
          value: true,
        });

        try {
          const invitation = window.localStorage.getItem('prevRecruitSessionInvitation');

          const res = await getWhiteLabel(contextState?.ux?.language);

          try {
            const { data } = await getColorAndLogoCompany(invitation);
            if (data) {
              res.colors.BANNER_COLOR_1 = data?.primaryColor;
              res.images.HEADER_LOGO = data?.logo;
            }
          } catch (e) {}

          setContextState({
            type: ActionTypes.SetWhitelabel,
            value: res,
          });
        } catch (error) {
          Sentry.captureException(error);
        } finally {
          setContextState({
            type: ActionTypes.SetLoading,
            value: false,
          });
        }
      }
    };

    fetchData();
  }, [contextState.ux.language]);

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider messages={language} locale={key} onError={() => {}}>
        <ErrorBoundary onError={handleError}>
          <Switch>
            {contextState.loading ? (
              <Route
                path='/'
                render={() => (
                  <div className='min-h-screen bg-white dark:bg-dark-bold'>
                    <Spinner show />
                  </div>
                )}
              />
            ) : (
              <>
                {!!contextState?.roles?.length &&
                  routes?.logged?.map((route, index) => {
                    return (
                      <ProtectedRoute
                        key={index}
                        isLogin={contextState?.isAuthenticated && contextState.isVerify}
                        isAuthorized={isSomeRoles(
                          route?.roles?.map(r => r.toUpperCase()) || [],
                          contextState?.roles?.map(r => r.toUpperCase()) || [],
                          route?.exceptRole?.map(r => r.toUpperCase()) || [],
                        )}
                        path={route?.path}
                        exact={route?.exact}
                        dashboard={route?.dashboard}
                        component={route.component}
                        profile={contextState?.profile || {}}></ProtectedRoute>
                    );
                  })}
                {routes?.loggedOut?.map((route, index) => (
                  <PublicRoute
                    key={index}
                    isLogin={contextState?.isAuthenticated && contextState.isVerify}
                    isAuthorized={true}
                    path={route?.path}
                    exact={route?.exact}
                    component={route.component}
                  />
                ))}
              </>
            )}
          </Switch>
        </ErrorBoundary>
      </IntlProvider>
    </QueryClientProvider>
  );
}

export default App;
