export const createUrl = (redirectURL, profile) => {
  redirectURL.search = new URLSearchParams({
    token: localStorage.getItem('session'),
    userId: profile,
  });
};

export const appendUrl = (redirectURL, appendKey, appendValue) => {
  redirectURL.searchParams.append(appendKey, appendValue);
};

export const windowHashIncludes = hash => {
  return window.location.hash && !window.location.hash.includes(hash) ? window.location.hash : '';
};

export const Window = () => {
  if (typeof window !== 'undefined') {
    return window;
  }
  return null;
};
