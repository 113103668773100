import React, { useEffect, useState } from 'react';
import { getCountries } from '../../api/addressService';
import {
  getGenders,
  getLanguages,
  getLevels,
  getProfile,
  getStudyArea,
  getTechnologies,
  saveProfile,
  getParentRoles,
} from '../../api/userService';
import { getFormationStatus, getFormationsInstitutesPaginated } from '../../api/formationService';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { Window } from '../../helpers/window';
// Libraries
import { useForm, FormProvider } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import moment from 'moment';
import { notification } from 'antd';
// Utils
import isComplete from '../../utils/steps/isComplete';
import { queryToObject } from '../../utils/processQueryString';
import { formatDefaultValues } from '../../utils/formatProfileData';
// Hooks
import useAboutMeValidations from '../../hooks/validations/useAboutMeValidations';
import useTranslations from '../../hooks/useTranslations';
import {
  birthdateValidations,
  countryCodeValidations,
  countryValidations,
  documentTypeValidations,
  documentValidations,
  genderValidations,
  lastNameValidations,
  locationValidations,
  nameValidations,
  phoneValidations,
  provinceValidations,
} from '../../hooks/validations/profileValidations';
import useRoles from '../../hooks/useRoles';
// Components
import ProfileCardHorizontal from './ProfileCardHorizontal/ProfileCardHorizontal';
import FormationCard from './FormationCard/FormationCard';
import LanguageCard from './LanguageCard/LanguageCard';
import TechCard from './TechCard/TechCard';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import Banner from '../Banner/Banner';
import Container from '../Container/Container';
import PortfolioCard from './PortfolioCard/PortfolioCard';
import { roles as rolesEnum } from '../../constants/enums';
import { DOCUMENT_TYPE_OPTIONS } from '../../constants/profile';
import { languagesList } from '../../utils/constants';

const Profile = ({ redirectProfile }) => {
  const { t } = useTranslations();
  const history = useHistory();
  const [cities, setCities] = React.useState([]);
  const [selectedOptionMenu, setSelectedOptionMenu] = useState('');
  const [profileIsSuccessfull, setProfileIsSuccessfull] = useState(false);
  const [techErrors, setTechErrors] = useState(false);
  const [certificateError, setCertificateError] = useState(false);
  const { contextState, setContextState } = useContextState();
  const [profile, setProfile] = useState();
  const { roles, isRole } = useRoles();
  const [picture, setPicture] = useState(contextState?.profile?.picture);
  const [aboutMeErrors, setAboutMeErrors] = useState({});
  const { validationsFn } = useAboutMeValidations(isComplete(contextState.profile), t);
  const query = queryToObject(history?.location?.search);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
    control,
    setValue,
  } = useForm({
    defaultValues: formatDefaultValues(contextState.profile),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setContextState({
      type: ActionTypes.SetLoading,
      value: true,
    });
    if (!Object.keys(contextState?.profile)?.length) {
      getProfile().then(response => {
        setContextState({
          type: ActionTypes.SetProfile,
          value: response,
        });
      });
    }
    if (!contextState.formation_status?.length) {
      getFormationStatus().then(response => {
        setContextState({
          type: ActionTypes.SetFormationStatus,
          value: response,
        });
      });
    }
    if (!Object.keys(contextState?.genders)?.length) {
      getGenders().then(response => {
        setContextState({
          type: ActionTypes.SetGenders,
          value: response,
        });
      });
    }
    if (!Object.keys(contextState?.countries)?.length) {
      getCountries().then(response => {
        setContextState({
          type: ActionTypes.SetCountries,
          value: response,
        });
      });
    }
    if (!Object.keys(contextState?.technologies)?.length) {
      getTechnologies('all').then(response => {
        setContextState({
          type: ActionTypes.SetTechnologies,
          value: response,
        });
      });
    }
    if (!Object.keys(contextState?.languages)?.length) {
      getLanguages().then(response => {
        setContextState({
          type: ActionTypes.SetLanguages,
          value: response,
        });
      });
    }
    if (!Object.keys(contextState?.levels)?.length) {
      getLevels().then(response => {
        setContextState({
          type: ActionTypes.SetLevels,
          value: response,
        });
      });
    }
    if (!contextState.formations?.length) {
      getFormationsInstitutesPaginated({}).then(response => {
        setContextState({
          type: ActionTypes.SetFormations,
          value: response,
        });
      });
    }
    if (!contextState.studyAreas?.length) {
      getStudyArea().then(response => {
        setContextState({
          type: ActionTypes.SetStudyAreas,
          value: response,
        });
      });
    }
    if (!contextState.jobRoles?.length) {
      getParentRoles().then(response => {
        setContextState({
          type: ActionTypes.SetJobRoles,
          value: response,
        });
      });
    }
    setSelectedOptionMenu(t('PROFILE'));
    setContextState({
      type: ActionTypes.SetLoading,
      value: false,
    });
    if (Object.keys(query ?? {})?.includes('edit')) {
      setContextState({
        type: ActionTypes.SetEditable,
        value: true,
      });
      history.replace({
        search: '',
      });
    }
  }, []);

  useEffect(() => {
    setProfile({ ...contextState?.profile, description: profile?.description ?? contextState?.profile?.description });
  }, [contextState?.profile]);

  React.useEffect(() => {
    if (watch('address.countryId')) {
      setCities(contextState?.countries?.find(country => country?.id === Number(watch('address.countryId')))?.cities);
    }
  }, [watch('address.countryId'), contextState?.countries]);

  useEffect(() => {
    setValue('genderId', contextState.profile?.genderId);
    setValue('address', contextState.profile.address);
  }, [contextState?.countries, contextState?.genders, contextState?.levels]);

  useEffect(() => {
    setValue('address.cityId', contextState?.profile?.address?.cityId);
  }, [cities]);
  useEffect(() => {
    setValue('preferredLanguage', contextState?.profile?.preferredLanguage);
  }, [contextState?.profile?.preferredLanguage]);

  useEffect(() => {
    setValue('jobRole.role', contextState?.profile?.jobRole?.role);
  }, [contextState?.jobRoles, contextState?.profile?.jobRole]);

  const menuOptionHandler = option => {
    setContextState({
      type: ActionTypes.SetEditable,
      value: option === t('PROFILE_EDIT_PROFILE'),
    });
    setSelectedOptionMenu(option);
  };

  const personalDataHandler = (value, target) => {
    if (
      target !== 'technologies' &&
      target !== 'technologiesRadio' &&
      target !== 'softwareDevelopmentRadio' &&
      target !== 'availability'
    ) {
      if (validationsFn(target, value)?.length > 0) {
        setAboutMeErrors({ ...aboutMeErrors, [target]: validationsFn(target, value) });
      } else {
        delete aboutMeErrors[target];
      }
    }
    if (target === 'countryId') {
      setProfile({ ...profile, address: { ...profile.address, [target]: Number(value), cityId: '' } });
      setAboutMeErrors({ ...aboutMeErrors, cityId: validationsFn('cityId', '') });
    } else if (target === 'cityId') {
      setProfile({ ...profile, address: { ...profile.address, [target]: Number(value) } });
    } else if (target === 'location') {
      setProfile({ ...profile, address: { ...profile.address, [target]: value } });
    } else if (target === 'gender' || target === 'nationality') {
      setProfile({ ...profile, [target]: { id: value } });
    } else if (target === 'areaCode' && !isNaN(value)) {
      if (value) {
        setProfile({ ...profile, [target]: !!Number(value) ? Number(value) : null });
      }
    } else if (target === 'phone' && !isNaN(value)) {
      if (value) {
        setProfile({ ...profile, [target]: value });
      }
    } else if (target === 'selectEnglish') {
      setProfile({ ...profile, languages: value ? [{ ...value }] : [] });
    } else if (target === 'certificateUrl') {
      setProfile({ ...profile, certificateUrl: value });
    } else if (target === 'description') {
      setProfile({ ...profile, description: value });
    } else {
      setProfile({ ...profile, [target]: value });
    }
  };

  const save = data => {
    setContextState({
      type: ActionTypes.SetEditable,
      value: false,
    });

    setLoadingSubmit(true);
    const dataToUpdate = {
      ...contextState.profile,
      ...data,
      document_type: data.document_type?.trim(),
      document_number: data.document_number?.trim(),
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim(),
      portfolios: data.portfolios.map(portfolio => ({
        url: portfolio.url,
      })),
      description: profile?.description?.trim(),
      linkedinUrl: data.linkedinUrl?.length > 0 ? data.linkedinUrl?.trim() : null,
      gender: { id: data.genderId },
      languages: data.languages.map(item => ({
        ...item,
        certificateUrl: item?.certificateUrl?.length ? item.certificateUrl : null,
      })),
      birthdate: moment(data.birthdate).format('YYYY-MM-DD'),
      technologies: profile?.technologies?.map(technology => ({
        technologyId: technology?.technologyId,
        technologyType: technology?.technologyType,
      })),
      formations: data.formations
        .filter(item => Object.keys(item).length)
        .map(formation => ({
          certificateUrl: formation?.certificateUrl?.length ? formation?.certificateUrl : null,
          instituteId: formation.institute.__isNew__ ? null : formation?.institute?.value ?? formation?.institute?.id,
          customCareer: formation?.customCareer,
          formationStatusId: formation?.formationStatusId?.toString(),
          title: formation.title?.trim(),
          customInstitute: formation.institute.label,
          endDate: formation?.endDate && moment(formation?.endDate).toISOString(),
          startDate: formation?.startDate && moment(formation?.startDate).toISOString(),
          studyArea:
            Object.values(formation.studyArea).length > 1
              ? {
                  id: formation.studyArea.__isNew__ ? null : formation.studyArea.id ?? formation.studyArea.value,
                  displayName: formation.studyArea.displayName ?? formation.studyArea.label,
                }
              : undefined,
          institute:
            Object.values(formation.institute).length > 1
              ? {
                  id: formation.institute.__isNew__ ? null : formation.institute.id ?? formation.institute.value,
                  displayName: formation?.institute?.displayName ?? formation.institute.label,
                }
              : undefined,
        })),
    };

    saveProfile(dataToUpdate)
      .then(() => {
        notification.open({
          message: t('PROFILE_EDIT_SUCCESS'),
          type: 'success',
        });
        setProfileIsSuccessfull(true);
        setContextState({
          type: ActionTypes.SetProfile,
          value: dataToUpdate,
        });
        reset(formatDefaultValues(dataToUpdate));
      })
      .catch(() => {
        notification.open({
          message: t('PROFILE_EDIT_FAILED'),
          type: 'error',
        });
      })
      .finally(() => setLoadingSubmit(false));
  };

  useEffect(() => {
    if (isDirty) {
      setContextState({
        type: ActionTypes.SetEditable,
        value: true,
      });
    } else {
      setContextState({
        type: ActionTypes.SetEditable,
        value: false,
      });
    }
  }, [isDirty]);

  useEffect(() => {
    if (contextState?.show) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [contextState?.show]);

  useEffect(() => {
    if (isDirty) {
      if (history.location.pathname !== '/profile') {
        history.replace('/profile');
      }
      if (Window()) {
        window.onbeforeunload = event => {
          event.returnValue = t('PROFILE_TEXT_SAVE_CHANGES_MODAL');
        };
      }
    }
    if (!isDirty && profileIsSuccessfull) {
      if (contextState?.redirectScreening?.redirect) {
        window.location.href = contextState?.redirectScreening?.url;
      }
    }
    return () => {
      if (Window()) {
        window.onbeforeunload = () => {};
      }
    };
  }, [isDirty]);

  return (
    <div className='h-full w-full'>
      <Prompt message={t('PROFILE_TEXT_SAVE_CHANGES_MODAL')} when={isDirty} />
      <div className='relative w-full'>
        <Banner text={`${t('MENU_ITEM_4')}`} svg='😎' />
        <div className='flex flex-col w-full relative pb-5'>
          <FormProvider setValue={setValue} register={register} watch={watch} control={control} formState={{ errors }}>
            <Container>
              <div className='flex flex-col md:flex-row w-full mx-auto description-container profile-container'>
                <ProfileMenu
                  profileImage={picture}
                  setPicture={setPicture}
                  firstName={watch('firstName') ?? profile?.firstName}
                  lastName={watch('lastName') ?? profile?.lastName}
                  linkedinUrl={contextState.profile.linkedinUrl}
                  formErrors={errors}
                  email={profile?.email}
                  register={register}
                  video={profile?.videoUrl}
                  description={profile?.description}
                  loading={contextState?.loading}
                  options={[t('PROFILE_EDIT_PROFILE')]}
                  selectedOption={selectedOptionMenu}
                  optionHandler={menuOptionHandler}
                  onChange={personalDataHandler}
                  redirectProfile={redirectProfile}
                  saveHandler={handleSubmit(save)}
                  errors={!!Object.keys(aboutMeErrors)?.length || certificateError}
                  watch={watch}
                  save={handleSubmit(save)}
                />
                <div className='flex grow flex-col mt-3 border-md'>
                  <div className='flex flex-col'>
                    <div className='flex flex-col text-muted md:pl-3'>
                      <div className='flex flex-col mb-3 grow user-heading p-6 bg-white dark:bg-dark-bold'>
                        <ProfileCardHorizontal
                          title={t('PROFILE_ABOUT_ME')}
                          labels={[t('PROFILE_NAME'), t('PROFILE_LASTNAME'), t('PROFILE_GENDER')]}
                          editable={true}
                          values={[
                            {
                              value: watch('firstName'),
                              target: 'firstName',
                              required: true,
                              register: register,
                              validations: nameValidations,
                              error: errors.firstName && errors.firstName.message,
                            },
                            {
                              value: watch('lastName'),
                              target: 'lastName',
                              required: true,
                              register: register,
                              validations: lastNameValidations,
                              error: errors.lastName && errors.lastName.message,
                            },
                            {
                              value: watch('genderId'),
                              target: 'genderId',
                              required: true,
                              options: contextState?.genders?.map(gender => ({
                                id: gender.id,
                                name: t(gender?.displayName?.toUpperCase()),
                              })),
                              register: register,
                              validations: genderValidations,
                              error: errors.genderId && errors.genderId.message,
                            },
                          ]}
                          loading={contextState?.loading}
                          titleColor='text-alkemy-black'
                          onChange={personalDataHandler}
                        />
                        <ProfileCardHorizontal
                          className='mt-0 lg:mt-5 '
                          loading={contextState?.loading}
                          labels={[t('PROFILE_DATE_OF_BIRTH'), t('PROFILE_CODE_COUNTRY'), t('PROFILE_PHONE_NUMBER')]}
                          editable={true}
                          control={control}
                          values={[
                            {
                              value: new Date(moment(watch('birthdate')).format('YYYY/MM/DD')),
                              target: 'birthdate',
                              required: true,
                              register: register,
                              validations: birthdateValidations,
                              error: errors.birthdate && errors.birthdate.message,
                            },
                            {
                              value: watch('countryCode'),
                              target: 'countryCode',
                              required: true,
                              type: 'number',
                              register: register,
                              validations: countryCodeValidations,
                              error: errors.countryCode && errors.countryCode.message,
                            },
                            {
                              value: watch('phone'),
                              target: 'phone',
                              valueAsNumber: true,
                              type: 'number',
                              required: true,
                              register: register,
                              validations: phoneValidations,
                              error: errors.phone && errors.phone.message,
                            },
                          ]}
                          titleColor='text-alkemy-black'
                          onChange={personalDataHandler}
                        />
                        <ProfileCardHorizontal
                          className='mb-2 mt-0 lg:mt-5'
                          labels={[t('PROFILE_TYPE_DOCUMENT'), t('PROFILE_DOCUMENT')]}
                          values={[
                            {
                              value: watch('document_type'),
                              target: 'document_type',
                              required: true,
                              options: DOCUMENT_TYPE_OPTIONS.map(data => ({
                                id: data.value,
                                name: data.name,
                              })),
                              register: register,
                              validations: documentTypeValidations,
                              error: errors?.document_type && errors?.document_type?.message,
                            },
                            {
                              value: watch('document_number'),
                              target: 'document_number',
                              type: 'string',
                              required: true,
                              register: register,
                              validations: documentValidations,
                              error: errors.document_number && errors.document_number.message,
                            },
                          ]}
                          titleColor='text-alkemy-black'
                          editable={true}
                          onChange={personalDataHandler}
                          loading={contextState?.loading}
                        />
                        <ProfileCardHorizontal
                          className='mb-2 mt-0 lg:mt-5'
                          labels={[t('PROFILE_COUNTRY'), t('PROFILE_PROVINCE'), t('PROFILE_LOCATION')]}
                          values={[
                            {
                              value: watch('address.countryId'),
                              target: 'address.countryId',
                              required: true,
                              options: contextState?.countries
                                .filter(country => country.isCertifiable)
                                ?.map(country => ({
                                  id: country.id,
                                  name: country.displayName,
                                })),
                              register: register,
                              validations: countryValidations,
                              error: errors?.address?.countryId && errors?.address?.countryId.message,
                            },
                            {
                              value: watch('address.cityId'),
                              target: 'address.cityId',
                              required: true,
                              options: cities?.map(city => ({ id: city.id, name: city.displayName })),
                              register: register,
                              validations: provinceValidations,
                              error: errors?.address?.cityId && errors?.address?.cityId.message,
                            },
                            {
                              value: watch('address.location'),
                              target: 'address.location',
                              required: true,
                              register: register,
                              validations: locationValidations,
                              error: errors?.address?.location && errors?.address?.location.message,
                            },
                          ]}
                          titleColor='text-alkemy-black'
                          editable={true}
                          onChange={personalDataHandler}
                          loading={contextState?.loading}
                        />
                        <ProfileCardHorizontal
                          className='mb-2 mt-0 lg:mt-5'
                          labels={[t('PROFILE_PREFERRED_LANGUAGE')]}
                          values={[
                            {
                              value: watch('preferredLanguage'),
                              target: 'preferredLanguage',
                              required: false,
                              options: languagesList?.map(x => ({
                                id: x.value,
                                name: x.label,
                              })),
                              register: register,
                            },
                          ]}
                          titleColor='text-alkemy-black'
                          editable={true}
                          onChange={personalDataHandler}
                          loading={contextState?.loading}
                        />
                      </div>
                      <div className='flex flex-col mb-3 p-6 user-heading bg-white dark:bg-dark-bold'>
                        <FormationCard
                          className=''
                          formations={profile?.formations}
                          loading={contextState.loading}
                          onChange={personalDataHandler}
                          editable={true}
                          profile={profile}
                          setProfile={setProfile}
                        />
                      </div>
                      <div className='flex flex-col mb-3 p-6 user-heading bg-white dark:bg-dark-bold'>
                        <LanguageCard
                          loading={contextState?.loading}
                          userLanguages={profile?.languages}
                          register={register}
                          control={control}
                          watch={watch}
                          certificateError={certificateError}
                          setCertificateError={setCertificateError}
                          languages={contextState?.languages}
                          levels={contextState?.levels}
                          onChange={personalDataHandler}
                          editable={true}
                          errors={aboutMeErrors?.certificateUrl && aboutMeErrors?.certificateUrl[0]}
                          profile={profile}
                          setProfile={setProfile}
                        />
                      </div>
                      <div className='flex flex-col mb-3 p-6 user-heading bg-white dark:bg-dark-bold'>
                        <PortfolioCard
                          register={register}
                          control={control}
                          errors={{}}
                          watch={watch}
                          handleSubmit={handleSubmit}
                          setValue={setValue}
                        />
                      </div>
                      {isRole(rolesEnum.ALKYMER) && contextState?.whitelabel?.behaviors?.SHOW_TECH_CARD && (
                        <div className='flex flex-col p-6 user-heading bg-white dark:bg-dark-bold'>
                          <TechCard
                            loading={contextState?.loading}
                            technologies={profile?.technologies}
                            options={contextState?.technologies}
                            onChange={personalDataHandler}
                            setError={setTechErrors}
                            messageError={aboutMeErrors?.technologies}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='w-full mt-3 flex justify-end'>
                    <button
                      data-testid='button-save-profile'
                      onClick={handleSubmit(save)}
                      disabled={loadingSubmit}
                      className='bg-alkemy-blue px-6 py-2 text-xs font-bold text-white rounded-full disabled:bg-opacity-60'>
                      {t('PROFILE_MENU_SAVE')}
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Profile;
