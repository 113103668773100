export const DOCUMENT_TYPE_OPTIONS = [
  { name: 'DNI', value: 'DNI' },
  { name: 'PASSPORT', value: 'PASSPORT' },
];

export const seniorityLevelTypes = [
  { name: 'TRAINEE' },
  { name: 'JUNIOR' },
  { name: 'SEMI_SENIOR' },
  { name: 'SENIOR' },
  { name: 'LIDER' },
];
