import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { ActionTypes, useContextState } from '../../redux/contextState';
import useTranslations from '../useTranslations';
import './CompleteProfileModal.scss';
import { useState } from 'react';
import { profileRoleErrors } from '../../constants/enums';

export default function useCompleteProfileModal() {
  const { t } = useTranslations();
  const { confirm } = Modal;
  const history = useHistory();
  const { contextState, setContextState } = useContextState();

  const [hasRendered, setHasRendered] = useState(false);

  const renderCompleteProfileModal = url => {
    if (hasRendered) return;

    setHasRendered(true);

    const roleName = contextState?.jobRoles?.find(r => r.id === contextState?.profile?.jobRole?.role)?.whitelabelName;

    const messageToShow = [profileRoleErrors.PROFILE_ROLE_HAS_TO_BE_UPDATED].includes(url)
      ? {
          title: t('REMINDER_UPDATE_PROFILE')
            .replace(`{{ROL}}${roleName ? '' : ' - '}`, t(`${roleName ?? ''}`))
            .replace('{{SENIORITY}}', t(`${contextState?.profile?.jobRole?.seniority}`)),
          okText: t('REMINDER_UPDATE_MODAL_COMPLETE'),
          cancelText: t('COMPLETE_PROFILE_MODAL_CLOSE'),
        }
      : [profileRoleErrors.PROFILE_ROLE_HAS_TO_BE_SET].includes(url)
      ? {
          title: t('REMINDER_SET_PROFILE'),
          okText: t('REMINDER_UPDATE_MODAL_COMPLETE'),
          cancelText: t('COMPLETE_PROFILE_MODAL_CLOSE'),
        }
      : {
          title: t('REMINDER_COMPLETE_PROFILE'),
          okText: t('COMPLETE_PROFILE_MODAL_COMPLETE'),
          cancelText: t('COMPLETE_PROFILE_MODAL_CLOSE'),
        };

    const modal = confirm({
      className: 'reminder-modal-wrapper',
      title: messageToShow.title,
      okText: messageToShow.okText,
      cancelText: messageToShow.cancelText,
      okButtonProps: {
        className: 'custom-modal-button custom-modal-button-complete text-white',
        id: 'custom-modal-button-id',
      },
      cancelButtonProps: {
        className: 'custom-modal-button redmindme-later-button text-lightGray',
      },
      onCancel() {
        modal.destroy();
      },
      onOk() {
        if (url) {
          setContextState({
            type: ActionTypes.SetRedirectScreening,
            value: { redirect: true, url },
          });
        }
        history.push('/profile');
      },
    });
  };

  return {
    renderCompleteProfileModal,
  };
}
