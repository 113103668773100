import axios from 'axios';
import { meetingsUrl } from '../constants/api';

const client = axios.create({ baseURL: meetingsUrl });
client.interceptors.request.use(async config => {
  const accessToken = localStorage.getItem('session');
  if (accessToken && !config.newAuthorization) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      ...(config.headers ?? {}),
    };
  }
  if (config.newAuthorization) {
    config.headers = {
      Authorization: config.newAuthorization,
    };
  }
  return config;
});

export const meetingsClient = client;
